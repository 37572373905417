<template>
  <div>
    <v-card>
      <v-card-title>Edit User</v-card-title>
      <v-card-text>
        <UserForm :dbUser="dbUser" :umUser="umUser" type="UPDATE" />
      </v-card-text>
    </v-card>

    <!-- <h2
      class="mt-10 mb-10 text-center"
    >{{umUser.name}} has {{assignedProjects.length}} {{activityName()}} assigned</h2> -->

    <h2 class="mt-10 mb-10 text-center">Projects assigned to {{umUser.name}}</h2>

    <v-data-table
      :headers="headers"
      :items="assignedProjects"
      item-key="projectId"
      show-select
      class="elevation-1"
      no-data-text="No Projects Assigned"
      v-model="selected"
    >
      <template v-slot="{ items }">
        <td>
          <v-checkbox v-model="items.selected" primary hide-details></v-checkbox>
        </td>
      </template>
    </v-data-table>

    <div class="mt-7">
      <v-btn
        @click="deleteProjects()"
        :disabled="selected.length == 0"
        color="primary"
        class="btn1"
      >Delete Projects</v-btn>
    </div>

    <v-dialog v-model="dialog" max-width="600">
      <template v-slot:activator="{ on }">
        <v-btn class="btn1 ml-5" color="primary" dark v-on="on">Add Project</v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Choose Project</v-card-title>

        <v-card-text grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md8>
              <v-autocomplete
                solo
                label="Projects"
                :items="projectList"
                item-text="name"
                item-value="_id"
                v-model="newProject"
                multiple
              ></v-autocomplete>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProject()">Add</v-btn>
          <v-btn color="primary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RestResource from "../../services/dataServiceBuyer.js";
import UserForm from "./form.vue";

const service = new RestResource();

export default {
  components: {
    UserForm
  },
  data() {
    return {
      dbUser: {},
      umUser: {},
      assignedProjects: [],
      projects: [],
      dialog: false,
      selected: [],
      projectList: [],
      selectedIds: [],
      newProject: [],
      headers: [
        { text: "Project Id", value: "projectId", sortable: false },
        { text: "Project Name", align: "left", sortable: false, value: "projectName" }
      ]
    };
  },
  mounted() {
    let data = {
      userId: this.$route.params.id,
      rawData: true
    };

    this.$setLoader();

    // TODO: Use fetchUser if getting one
    service.fetchUsers(data).then(r => {
      this.$disableLoader();
      this.umUser = r.data[0].umUser;
      this.dbUser = r.data[0].dbUser;
    });

    this.getProjects();
    this.loadProjects();
  },

  methods: {
    getProjects() {
      this.$setLoader();

      service.getProjectsForUser({ userId: this.$route.params.id }).then(r => {
        this.assignedProjects = r.data.projectDetails;
        this.$disableLoader();
      });
    },

    //   activityName() {
    //   if (
    //     this.assignedProjects.length == 1 ||
    //     this.assignedProjects.length == 0
    //   ) {
    //     return "project";
    //   } else if (this.assignedProjects.length > 1) {
    //     return "projects";
    //   } else {
    //     return undefined;
    //   }
    // },


    loadProjects() {
      service.fetchProjects().then(r => {
        this.projectList = r.data;
      });
    },

    addProject() {
      let projectData = {
        userId: [ this.$route.params.id ],
        projectId: this.newProject
      };

      this.$setLoader();
      service.assignProjectsToUser(projectData).then(() => {
        this.$disableLoader();
        this.getProjects();
        this.dialog = false;
      }).catch((error) => {
        this.$disableLoader()
        this.dialog = false
        alert("There is an error" + error)
      })
    },

    deleteProjects() {
      // TODO: Refactor to map
      this.selected.forEach(sIds => {
        this.selectedIds.push(sIds.projectId);
      });

      let data = {
        userId: this.$route.params.id,
        selectedProjectIds: this.selectedIds
      };

      this.$setLoader();
      service.deleteProjectsInUsers(data).then(() => {
        this.getProjects();
        this.$disableLoader();
      }).catch(error => {
        alert("There is an error" + error);
      });
    }
  }
};
</script>


<style scoped>
.btn1 {
  float: left;
  margin-right: 5px;
}
</style>>





